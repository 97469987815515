import axios from 'axios';
import {getToken} from '@iso/lib/helpers/utility';
import {put} from 'redux-saga/effects';
import actions from '../../redux/auth/actions';
import {verify} from "jsonwebtoken";
// const history = createBrowserHistory();
// const fakeApiCall = false; // auth0 or express JWT
const timeout = 60000;
const apiUrl = process.env.REACT_APP_API_URL;
// const systemId = process.env.REACT_APP_SYSTEM_ID;

let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

export function* get(uri, data, page, sort, token) {
    // this.checkToken();
    const sessionToken = getToken().get('idToken') ? getToken().get('idToken') : token ? token : null;
    // if (token) {
    headers = {
        ...headers,
        authorization: `Bearer ${sessionToken}`,
    };
    // }
    if (page) {
        headers = {
            ...headers,
            Range: `${page.page || 1}-${page.limit || 10}`,
        };
    }
    if (!sort) {
        sort = {
            sort: 'asc',
            sortBy: 'createdAt',
        };
    }
    const params = data ? toParams(data) : '';
    const sortField = `&sort=${sort.sort === 'asc' ? '' : '-'}${sort.sortBy}`;
    return yield fetch(`${apiUrl}${uri}?${params}${sortField}`, {
        headers: headers,
        method: 'GET',
    });
}

export function* post(uri, data) {
    return yield fetch(`${apiUrl}${uri}`, {
        headers: headers,
        method: 'POST',
        data,
    });
}
export function* postOtp(uri, data, token) {
    headers = {
        ...headers,
        authorization: `Bearer ${token}`,
    };
    return yield fetch(`${apiUrl}${uri}`, {
        headers: headers,
        method: 'POST',
        data,
    });
}
export function* putOtp(uri, data, token) {
    headers = {
        ...headers,
        authorization: `Bearer ${token}`,
    };
    return yield fetch(`${apiUrl}${uri}`, {
        headers: headers,
        method: 'PUT',
        data,
    });
}
export function* postBlob(uri, data) {
    return yield fetch(`${apiUrl}${uri}`, {
        headers: headers,
        method: 'POST',
        data,
        responseType: 'blob',
    });
}
export function* postArrayBuffer(uri, data) {
    return yield fetch(`${apiUrl}${uri}`, {
        headers: headers,
        method: 'POST',
        data,
        responseType: 'arraybuffer',
    });
}
export function* PUT(uri, data) {
    return yield fetch(`${apiUrl}${uri}`, {
        headers: headers,
        method: 'PUT',
        data,
    });
}

export function* DELETE(uri, data) {
    return yield fetch(`${apiUrl}${uri}`, {
        headers: headers,
        method: 'DELETE',
        data,
    });
}

function toParams(data) {
    const url = Object.keys(data).map((k) => {
        return `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`;
    }).join('&');
    return url;
}

function* forbidden() {
    yield localStorage.setItem('forbidToken', 1);
    yield put({
        type: actions.CHECK_AUTHORIZATION,
    });
}

export function* fetch(url, options) {
    const result = yield axios.request({
        ...options,
        url,
        baseURL: apiUrl,
        timeout: timeout,
        headers: headers
    }).then((res) => {
        return res;
    }).catch((error) => {
        return error
    })
    try {
        const response = result.status ? result : result.response ? result.response : null;
        if (!response) return null;
        if (response.status === 401 || response.status === 403) {
            yield forbidden();
        } else {
            if (!response.data) return response;
            const { payload } = verify(response.data, process.env.REACT_APP_JSONWEBTOKEN_SECRET_KEY);
            return {...response, data: payload};
        }
    } catch (error) {
        return error
    }
}