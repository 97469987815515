import * as _ from 'lodash';

import { PUT, get, post } from '@iso/lib/helpers/resApiRequestor';
import { all, cancel, fork, put, select, takeEvery } from 'redux-saga/effects';

import actions from './actions';

export function* withdrawalTransaction() {
  yield takeEvery('GET_WITHDRAWAL_TRANSACTION', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    payload.mid = merchantId;
    const loadmore = payload.loadmore;
    try {
      if (loadmore) {
        yield put({
          type: actions.LOADMORE_WITHDRAWAL_TRANSACTION,
        });
      } else {
        yield put({
          type: actions.GETTING_WITHDRAWAL_TRANSACTION,
        });
      }
      // const apiResultD = yield withdrawalRequesting();
      // const apiResultD = yield withdrawalRequestingNew(merchantId, role, selectedMerchant);
      const apiResultD = yield withdrawalTransactionRequest(payload);
      const result = apiResultD.data || null;
      if (result.success) {
        const currentList = payload.currentUserList;
        const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
        yield put({
          type: actions.GET_WITHDRAWAL_TRANSACTION_SUCCESS,
          payload: finalData,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.GET_WITHDRAWAL_TRANSACTION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_WITHDRAWAL_TRANSACTION_ERROR,
      });
    }
  });
}
export function* splitTransaction() {
  yield takeEvery('GET_SPLIT_TRANSACTION', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    payload.mid = merchantId;
    const loadmore = payload.loadmore;
    try {
      if (loadmore) {
        yield put({
          type: actions.LOADMORE_SPLIT_TRANSACTION,
        });
      } else {
        yield put({
          type: actions.GETTING_SPLIT_TRANSACTION,
        });
      }
      const apiResultD = yield splitTransactionRequest(payload);
      const result = apiResultD.data || null;
      if (result.success) {
        const currentList = payload.currentUserList;
        const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
        yield put({
          type: actions.GET_SPLIT_TRANSACTION_SUCCESS,
          payload: finalData,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.GET_SPLIT_TRANSACTION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_SPLIT_TRANSACTION_ERROR,
      });
    }
  });
}
export function* topupTransaction() {
  yield takeEvery('GET_TOPUP_TRANSACTION', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const identity = state.Auth.identity;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    payload.mid = merchantId;
    payload.tenantId = identity.tenantId;
    const loadmore = payload.loadmore;
    try {
      if (loadmore) {
        yield put({
          type: actions.LOADMORE_TOPUP_TRANSACTION,
        });
      } else {
        yield put({
          type: actions.GETTING_TOPUP_TRANSACTION,
        });
      }
      const apiResultD = yield topupTransactionRequest(payload);
      const result = apiResultD.data || null;
      if (result.success) {
        const currentList = payload.currentUserList;
        const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
        yield put({
          type: actions.GET_TOPUP_TRANSACTION_SUCCESS,
          payload: finalData,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.GET_TOPUP_TRANSACTION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_TOPUP_TRANSACTION_ERROR,
      });
    }
  });
}
export function* getOnlinePaymentTransaction() {
  yield takeEvery('GET_ONLINE_PAYMENT_TRANSACTION', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const identity = state.Auth.identity;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    payload.mid = merchantId;
    payload.tenantId = identity.tenantId;
    const loadmore = payload.loadmore;
    try {
      if (loadmore) {
        yield put({
          type: actions.LOADMORE_ONLINE_PAYMENT_TRANSACTION,
        });
      } else {
        yield put({
          type: actions.GETTING_ONLINE_PAYMENT_TRANSACTION,
        });
      }
      const apiResultD = yield onlinePaymentTransactionRequest(payload);
      const result = apiResultD.data || null;
      if (result.success) {
        const currentList = payload.currentUserList;
        const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
        yield put({
          type: actions.GET_ONLINE_PAYMENT_TRANSACTION_SUCCESS,
          payload: finalData,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.GET_ONLINE_PAYMENT_TRANSACTION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_ONLINE_PAYMENT_TRANSACTION_ERROR,
      });
    }
  });
}
export function* autoSettlement() {
  yield takeEvery('GET_ALL_AUTOSETTLEMENT', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    payload.mid = merchantId;
    const loadmore = payload.loadmore;
    try {
      if (loadmore) {
        yield put({
          type: actions.LOADMORE_AUTOSETTLEMENT,
        });
      } else {
        yield put({
          type: actions.GETTING_ALL_AUTOSETTLEMENT,
        });
      }
    const apiResultD = yield getAutoSettlement(payload);
    const result = apiResultD.data || null;
      if (result.success) {
        const currentList = payload.currentUserList;
        const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
        yield put({
          type: actions.GOT_ALL_AUTOSETTLEMENT,
          payload: finalData,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.GET_ALL_AUTOSETTLEMENT_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_ALL_AUTOSETTLEMENT_ERROR,
      });
    }
  });
}
export function* loadBalance() {
  yield takeEvery('LOAD_WALLET_BALANCE', function* () {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const walletData = state.Financials.walletId;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    const loadingBalances = state.Financials.loadingBalances;
    if (!loadingBalances) {
      try {
        yield put({
          type: actions.LOADING_BALANCES,
        });
        yield put({
          type: actions.LOADING_LIMIT,
        });
        yield put({
          type: actions.LOADING_BANK_ACCOUNTS,
        });
        const initialWallet = !walletData ? yield fetchingInitialWallet(merchantId) : null;
        const walletId = walletData ? walletData.walletId : initialWallet && initialWallet.data && initialWallet.data.data ? initialWallet.data.data.walletId : '';
        if (walletId) {
          // const apiResult = yield fetchBalance(initialWallet.data.data.walletId);
          const apiResult = yield fetchBalanceNew(walletId);
          const result = apiResult.data;
          if (result.success) {
            yield put({
              type: actions.BALANCES_LOADED,
              walletDetails: result.data,
            });
            yield put({
              type: actions.FETCHED_ROLLING_RESERVE,
              payload: result.data.rollingReserve,
            });
            const apiResultC = yield getLimit(merchantId);
            const resultc = apiResultC.data || null;
            if (apiResultC.status === 200) {
              yield put({
                type: actions.LIMIT_LOADED,
                limitDetails: resultc,
              });
              const apiResultD = yield fetchBankAccount(merchantId);

              const resultd = apiResultD.data || null;
              if (resultd.success) {
                yield put({
                  type: actions.BANK_ACCOUNTS_LOADED,
                  bankAccounts: resultd.data,
                });
              } else {
                yield put({
                  type: actions.LOADING_BANK_ACCOUNTS_FAILED,
                });
              }

            } else {
              yield put({
                type: actions.LOADING_LIMIT_FAILED,
              });
            }
          } else {
            yield put({
              type: actions.LOADING_BALANCES_FAILED,
            });
          }
        }
      } catch (error) {
        yield put({
          type: actions.LOADING_BALANCES_FAILED,
        });
      }
    }
  });
}
export function* loadBankAccounts() {
  yield takeEvery('LOAD_BANK_ACCOUNTS', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    const loadingBalances = state.Financials.loadingBalances;
    if (!loadingBalances) {
      try {
        yield put({
          type: actions.LOADING_BANK_ACCOUNTS,
        });
        const apiResultD = yield fetchBankAccount(merchantId);
        const resultd = apiResultD.data || null;
        if (resultd.success) {
          yield put({
            type: actions.BANK_ACCOUNTS_LOADED,
            bankAccounts: resultd.data,
          });
        } else {
          yield put({
            type: actions.LOADING_BANK_ACCOUNTS_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.LOADING_BANK_ACCOUNTS_FAILED,
        });
      }
    }
  });
}
export function* requestOtp() {
  yield takeEvery('REQUEST_OTP', function* ({ payload }) {
    try {
      yield put({
        type: actions.REQUESTING_OTP,
        payload: true,
      });
      const apiResultD = yield requestingOtp(payload);
      const result = apiResultD.data || null;
      if (result.success) {
        yield put({
          type: actions.REQUESTING_OTP,
          payload: false,
        });
      } else {
        yield put({
          type: actions.REQUEST_OTP_FAILED,
        });
      }
      yield put({
        type: actions.REQUESTED_OTP,
        payload: result.success ? result.data : result.message ? result.message : 'Error in sending otp!',
        isErrorOtp: result.success ? false : true,
      });
    } catch (error) {
      yield put({
        type: actions.REQUEST_OTP_FAILED,
      });
    }
  });
}
export function* verifyOtp() {
  yield takeEvery('VERIFY_OTP', function* ({ signature, payload, accountType }) {
    try {
      yield put({
        type: actions.VERIFYING_OTP,
        payload: true,
      });
      let apiResult;
      if (accountType === 'Corporate') {
        apiResult = yield verifyingOtpCorporate(payload);
      } else {
        apiResult = yield verifyingOtp(signature, payload);
      }
      const result = apiResult.data || null;
      if (result.status_text) {
        yield put({
          type: actions.VERIFIED_OTP,
          payload: 'Your withdraw is currently processing!',
          isErrorVerifyOtp: false,
        });
      } else {
        yield put({
          type: actions.VERIFY_OTP_FAILED,
        });
        if (result.code) {
          yield put({
            type: actions.VERIFIED_OTP,
            payload: result.advise,
            isErrorVerifyOtp: true,
          });
        } else if (result.response_code) {
          yield put({
            type: actions.VERIFIED_OTP,
            payload: result.response_message,
            isErrorVerifyOtp: true,
          });
        }
      }
      yield put({
        type: actions.VERIFYING_OTP,
        payload: false,
      });
    } catch (error) {
      yield put({
        type: actions.VERIFY_OTP_FAILED,
      });
    }
  });
}
export function* loadSupportedBanks() {
  yield takeEvery('LOAD_SUPPORTED_BANKS', function* ({ payload }) {
    const state = yield select();
    const loadBankAccounts = state.Financials.loadBankAccounts;
    if (!loadBankAccounts) {
      try {
        yield put({
          type: actions.LOADING_SUPPORTED_BANKS,
        });
        const apiResult = yield getSupportedBanks();
        const result = apiResult.data || null;
        if (result.success) {
          yield put({
            type: actions.SUPPORTED_BANK_LOADED,
            supportedBanks: result.data,
          });
        } else {
          yield put({
            type: actions.LOADING_SUPPORTED_BANKS_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.LOADING_SUPPORTED_BANKS_FAILED,
        });
      }
    }
  });
}
export function* sendSmsOtp() {
  yield takeEvery('SEND_SMS_OTP', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.SENDING_SMS_OTP,
        });
        const apiResult = yield sendOtpSms(payload);
        const result = apiResult.data || null;
        if (result.success) {
          yield put({
            type: actions.SMS_OTP_SENT,
            otpMessage: result.message,
            otpSent: true,
          });
        } else {
          yield put({
            type: actions.SENDING_SMS_OTP_FAILED,
            otpMessage: result.message,
            otpSent: false,
          });
        }
      } catch (error) {
        yield put({
          type: actions.SENDING_SMS_OTP_FAILED,
          otpMessage: 'Error on sending sms otp.',
          otpSent: false,
        });
      }
    }
  });
}
export function* saveBankAccount() {
  yield takeEvery('SAVE_BANK_ACCOUNT', function* ({ payload }) {
    const state = yield select();
    const merchantId = state.Auth.identity.merchantId;
    if (payload) {
      try {
        yield put({
          type: actions.SAVING_BANK_ACCOUNT,
        });
        const apiResult = yield insertBankAccount(payload);
        const result = apiResult.data || null;
        if (result.success) {
          const apiResultB = yield fetchBankAccount(merchantId);
          const bankAccounts = apiResultB.data || null;
          if (bankAccounts.success) {
            yield put({
              type: actions.BANK_ACCOUNT_SAVED,
              result: result.data,
              bankAccounts: bankAccounts.data,
            });
          } else {
            yield put({
              type: actions.BANK_ACCOUNT_SAVED,
              result: result.data,
            });
          }
        } else {
          yield put({
            type: actions.SAVING_BANK_ACCOUNT_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.SAVING_BANK_ACCOUNT_FAILED,
        });
      }
    }
  });
}
export function* resendOtp() {
  yield takeEvery('RESEND_OTP', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.RESENDING_OTP,
        });
        const apiResult = yield resendOtpSms(payload);
        const result = apiResult.data || null;
        if (result === "Success") {
          yield put({
            type: actions.RESEND_OTP_SENT,
            message: 'OTP successfully resend!',
          });
        } else if (result === 'FAILED NO MOBILE FOUND') {
          yield put({
            type: actions.RESENDING_OTP_FAILED,
            message: 'NO OTP RECIPIENT',
          });
        } else {
          yield put({
            type: actions.RESENDING_OTP_FAILED,
            message: 'OTP failed to resend!',
          });
        }
      } catch (error) {
        yield put({
          type: actions.RESENDING_OTP_FAILED,
          message: 'OTP failed to resend!',
        });
      }
    }
  });
}
export function* wResendOtp() {
  yield takeEvery('W_RESEND_OTP', function* ({ payload }) {
    try {
      yield put({
        type: actions.W_RESENDING_OTP,
        payload: true,
      });
      const apiResultD = yield resendingOtp(payload);
      const result = apiResultD.data || null;
      if (result.success) {
        yield put({
          type: actions.W_RESEND_OTP_SUCCESS,
          payload: result.data,
          isErrorResendOtp: false,
        });
      } else {
        yield put({
          type: actions.W_RESEND_OTP_SUCCESS,
          payload: result.message ? result.message : 'Error in resending otp!',
          isErrorResendOtp: true,
        });
      }
      yield put({
        type: actions.W_RESENDING_OTP,
        payload: false,
      });
    } catch (error) {
      yield put({
        type: actions.W_RESEND_OTP_FAILED,
      });
    }
  });
}
export function* updateBankAccount() {
  yield takeEvery('UPDATE_BANK_ACCOUNT', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.UPDATING_BANK_ACCOUNT,
        });
        const apiResult = yield editBankAccount(payload);
        const result = apiResult.data || null;
        if (result.success) {
          yield put({
            type: actions.BANK_ACCOUNT_UPDATED,
            updatedBankAccount: result.data.finalUpdatedData,
            bankAccounts: result.data.bankAccountUpdated,
          });
        } else {
          yield put({
            type: actions.UPDATING_BANK_ACCOUNT_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.UPDATING_BANK_ACCOUNT_FAILED,
        });
      }
    }
  });
}
export function* removeBankAccount() {
  yield takeEvery('REMOVE_BANK_ACCOUNT', function* ({ payload }) {
    const state = yield select();
    const bankAccounts = state.Financials.bankAccounts;
    if (payload) {
      try {
        yield put({
          type: actions.REMOVING_BANK_ACCOUNT,
        });
        const apiResult = yield deleteBankAccount(payload);
        const result = apiResult.data || null;
        if (result.success) {
          const updatedAccounts = _.reject(bankAccounts, { Id: payload });
          yield put({
            type: actions.BANK_ACCOUNT_REMOVED,
            bankAccounts: updatedAccounts,
          });
        } else {
          yield put({
            type: actions.REMOVING_BANK_ACCOUNT_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.REMOVING_BANK_ACCOUNT_FAILED,
        });
      }
    }
  });
}
export function* changeFavorite() {
  yield takeEvery('CHANGE_FAVORITE', function* ({ payload }) {
    const state = yield select();
    const merchantId = state.Auth.identity.merchantId;
    payload.mid = merchantId;
    if (payload) {
      try {
        yield put({
          type: actions.CHANGING_FAVORITE,
        });
        const apiResult = yield changeFavoriteValue(payload);
        const result = apiResult.data || null;

        if (result.success) {
          payload.page.page = 1;
          payload.page.limit = payload.page.limit * payload.newPage;
          const apiResultB = yield fetchEnrolledBankAccountResult(payload);
          const bankAccounts = apiResultB.data || null;
          
          if (bankAccounts.success) {
            bankAccounts.data.pagination.page = payload.newPage;
            bankAccounts.data.pagination.pageCount = payload.newPageCount;
            bankAccounts.data.pagination.pageSize = 30;
            yield put({
              type: actions.FAVORITE_CHANGED,
              // bankAccounts: bankAccounts.data,
              bankAccounts: bankAccounts.data.result,
              pagination: bankAccounts.data.pagination,
            });
          } else {
            yield put({
              type: actions.FAVORITE_CHANGED,
            });
          }
        } else {
          yield put({
            type: actions.CHANGING_FAVORITE_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.CHANGING_FAVORITE_FAILED,
        });
      }
    }
  });
}
export function* changeStatus() {
  yield takeEvery('CHANGE_STATUS', function* ({ payload }) {
    const state = yield select();
    const merchantId = state.Auth.selectedMerchant;
    payload.mid = merchantId;
    if (payload) {
      try {
        yield put({
          type: actions.CHANGING_STATUS,
        });
        const apiResult = yield changeStatusValue(payload);
        const result = apiResult.data || null;
        if (result.success) {
          payload.page.page = 1;
          payload.page.limit = payload.page.limit * payload.newPage;
          const apiResultB = yield fetchEnrolledBankAccountResult(payload);
          const bankAccounts = apiResultB.data || null;

          if (bankAccounts.success) {
            bankAccounts.data.pagination.page = payload.newPage;
            bankAccounts.data.pagination.pageCount = payload.newPageCount;
            bankAccounts.data.pagination.pageSize = 30;
            yield put({
              type: actions.STATUS_CHANGED,
              // bankAccounts: bankAccounts.data,
              bankAccounts: bankAccounts.data.result,
              pagination: bankAccounts.data.pagination,
            });
          } else {
            yield put({
              type: actions.STATUS_CHANGED,
            });
          }
        } else {
          yield put({
            type: actions.CHANGING_STATUS_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.CHANGING_STATUS_FAILED,
        });
      }
    }
  });
}
export function* downloadWithdrawal() {
  yield takeEvery('DOWNLOAD_WITHDRAWAL', function* ({ payload }) {
    // const merchantId = state.Auth.identity.merchantId;
    if (payload) {
      try {
        yield put({
          type: actions.DOWNLOAD_WITHDRAWAL_LOADING,
          payload: true,
        });
        const apiResult = yield downloadWithdrawalReport(payload);
        const result = apiResult.data || null;
        if (!result.success) {
          yield put({
            type: actions.DOWNLOAD_WITHDRAWAL_MESSAGE,
            payload: result.message
          });
        }
      } catch (error) {
        yield put({
          type: actions.DOWNLOAD_WITHDRAWAL_ERROR,
        });
      }
    }
  });
}
export function* downloadSplitPayment() {
  yield takeEvery('DOWNLOAD_SPLIT', function* ({ payload }) {
    // const merchantId = state.Auth.identity.merchantId;
    if (payload) {
      try {
        yield put({
          type: actions.DOWNLOAD_SPLIT_LOADING,
          payload: true,
        });
        const apiResult = yield downloadSplitReport(payload);
        const result = apiResult.data || null;
        if (!result.success) {
          yield put({
            type: actions.DOWNLOAD_SPLIT_MESSAGE,
            payload: result.message
          });
        }
      } catch (error) {
        yield put({
          type: actions.DOWNLOAD_SPLIT_ERROR,
        });
      }
    }
  });
}
export function* downloadTopup() {
  yield takeEvery('DOWNLOAD_TOPUP', function* ({ payload }) {
    // const merchantId = state.Auth.identity.merchantId;
    if (payload) {
      try {
        yield put({
          type: actions.DOWNLOAD_TOPUP_LOADING,
          payload: true,
        });
        const apiResult = yield downloadTopupReport(payload);
        const result = apiResult.data || null;
        if (!result.success) {
          yield put({
            type: actions.DOWNLOAD_TOPUP_MESSAGE,
            payload: result.message
          });
        }
      } catch (error) {
        yield put({
          type: actions.DOWNLOAD_TOPUP_ERROR,
        });
      }
    }
  });
}
export function* downloadBizwalletReport() {
  yield takeEvery('DOWNLOAD_BIZWALLET_REPORT', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.DOWNLOADING_BIZWALLET_REPORT,
        });
        // ADD API CALL HERE - DOWNLOAD BIZWALLET REPORT
        const apiResult = yield downloadBizReport(payload);
        const result = apiResult.data || null;
        if (!result.success) {
          yield put({
            type: actions.DOWNLOAD_BIZWALLET_REPORT_ERROR,
            payload: result.message
          });
        }
      } catch (error) {
        yield put({
          type: actions.DOWNLOAD_BIZWALLET_REPORT_ERROR,
          payload: 'Failed to download bizwallet report.'
        });
      }
    }
  });
}
export function* downloadAutoSettlement() {
  yield takeEvery('DOWNLOAD_AUTO_SETTLEMENT', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.DOWNLOAD_AUTO_SETTLEMENT_LOADING,
          payload: true,
        });
        const apiResult = yield downloadAutoSettlementReport(payload);
        const result = apiResult.data || null;
        if (!result.success) {
          yield put({
            type: actions.DOWNLOAD_AUTO_SETTLEMENT_MESSAGE,
            payload: result.message
          });
        }
      } catch (error) {
        yield put({
          type: actions.DOWNLOAD_AUTO_SETTLEMENT_ERROR,
        });
      }
    }
  });
}

export function* downloadOnlinePayment() {
  yield takeEvery('DOWNLOAD_ONLINE_PAYMENT', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.DOWNLOAD_ONLINE_PAYMENT_LOADING,
          payload: true,
        });
        const apiResult = yield downloadOnlinePaymentReport(payload);
        const result = apiResult.data || null;
        if (result.success) {
          yield put({
            type: actions.DOWNLOAD_ONLINE_PAYMENT_LOADED,
            payload: result.data,
          });
        } else  {
          yield put({
            type: actions.DOWNLOAD_ONLINE_PAYMENT_MESSAGE,
            payload: result.response_message,
          });
        }
      } catch (error) {
        yield put({
          type: actions.DOWNLOAD_ONLINE_PAYMENT_ERROR,
        });
      }
    }
  });
}

export function* fetchwithdrawalRequest() {
  yield takeEvery('FETCH_WITHDRAWAL_REQUEST', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.FETCHING_WITHDRAWAL_REQUEST,
          payload: true,
        });
        const apiResult = yield withdrawalRequestingByReqId(payload);
        const result = apiResult.data || null;
        if (result.success) {
          yield put({
            type: actions.FETCH_WITHDRAWAL_REQUEST_SUCCESS,
            payload: result.data
          });
        } else {
          yield put({
            type: actions.FETCH_WITHDRAWAL_REQUEST_FAILED,
            payload: 0,
          });
        }
      } catch (error) {
        yield put({
          type: actions.FETCH_WITHDRAWAL_REQUEST_FAILED,
          payload: 0,
        });
      }
    }
  });
}
export function* updateWithdrawalStatus() {
  yield takeEvery('UPDATE_WITHDRAWAL_STATUS', function* ({ payload }) {
    const state = yield select();
    const withdrawalData = state.Financials.withdrawTransactionData;
    const pagination = state.Financials.withdrawTransactionPagination;
    if (payload) {
      try {
        yield put({
          type: actions.UPDATING_WITHDRAWAL_STATUS,
          payload: true,
        });
        const apiResult = yield updatingWithdrawalStatus(payload);
        const result = apiResult.data || null;
        if (result.success) {

          const foundIndex = withdrawalData.findIndex(x => x.requestId === payload.requestId);
          withdrawalData[foundIndex] = result.data;
          // REPLACE OLD WITHDRAWAL DATA WITH NEW
          // yield put({
          //   type: actions.GET_WITHDRAWAL_REQUEST_SUCCESS,
          //   payload: withdrawalData
          // });
          yield put({
            type: actions.GET_WITHDRAWAL_TRANSACTION_SUCCESS,
            payload: withdrawalData,
            pagination,
          });
          yield put({
            type: actions.UPDATING_WITHDRAWAL_STATUS,
            payload: false
          });
          yield put({
            type: actions.UPDATED_WITHDRAWAL_STATUS,
            payload: result.data
          });
        }
      } catch (error) {
        yield put({
          type: actions.UPDATING_FAILED_WITHDRAWAL_STATUS,
          payload: false,
        });
      }
    }
  });
}
export function* sendAutosweepOtp() {
  yield takeEvery('SEND_AUTOSWEEP_OTP', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.SENDING_AUTOSWEEP_OTP,
        });
        const apiResult = yield sendOtpSms(payload);
        const result = apiResult.data || null;
        if (result.success) {
          yield put({
            type: actions.AUTOSWEEP_OTP_SENT,
            otpSent: true,
            otpMessage: result.message,
          });
        } else {
          yield put({
            type: actions.SENDING_AUTOSWEEP_OTP_FAILED,
            otpSent: false,
            otpMessage: result.message,
          });
        }
      } catch (error) {
        yield put({
          type: actions.SENDING_AUTOSWEEP_OTP_FAILED,
          otpSent: false,
          otpMessage: 'Error on sending sms otp.',
        });
      }
    }
  });
}
export function* saveAutoSweep() {
  yield takeEvery('SAVE_AUTO_SWEEP', function* ({ payload, process }) {
    if (payload) {
      try {
        yield put({
          type: actions.SAVING_AUTO_SWEEP,
        });
        let apiResult;
        if (process === 'Add') {
          delete payload.id;
          apiResult = yield insertAutoSweep(payload);
        } else {
          apiResult = yield changeAutoSweepStatus(payload);
        }
        const result = apiResult.data || null;
        if (result.success) {
          yield put({
            type: actions.SAVED_AUTO_SWEEP,
            result: result,
            message: payload.status === 'ENABLED' ? 'Autosweep successfully activated' : 'Autosweep successfully deactivated',
          });
        } else {
          yield put({
            type: actions.SAVING_AUTO_SWEEP_FAILED,
            message: result.message,
          });
        }
      } catch (error) {
        yield put({
          type: actions.SAVING_AUTO_SWEEP_FAILED,
          message: error,
        });
      }
    }
  });
}
export function* walletTopUp() {
  yield takeEvery('WALLET_TOP_UP', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.TOPPING_UP,
        });
        const apiResult = yield walletTopUpRequest(payload);
        const result = apiResult.data;
        if (result.success) {
          yield put({
            type: actions.WALLET_TOP_UP_SUCCESS,
            result: result.data,
          });
        } else {
          yield put({
            type: actions.WALLET_TOP_UP_FAILED,
            message: result.message,
          });
        }
      } catch (error) {
        yield put({
          type: actions.WALLET_TOP_UP_FAILED,
          message: 'Topup failed, cannot process transaction.',
        });
        yield cancel();
      }
    }
  });
}
export function* sendReportEmail() {
  yield takeEvery('SEND_REPORT_EMAIL', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.SENDING_REPORT_EMAIL,
        });
        let apiResult;
        if (!payload.isImporterExporter) {
          apiResult = yield sendingReportEmail(payload);
        } else {
          apiResult = yield sendingReportEmailImporterExporter(payload);
        }
        const result = apiResult.data;
        if (result.success) {
          yield put({
            type: actions.DONE_SENDING_REPORT_EMAIL,
            result: 'Success on Sending BOC Report, Please check your email!',
          });
        } else {
          yield put({
            type: actions.ERROR_REPORT_EMAIL,
            message: result.message,
          });
        }
      } catch (error) {
        yield put({
          type: actions.ERROR_REPORT_EMAIL,
          message: 'Sending boc report failed.',
        });
        yield cancel();
      }
    }
  });
}
export function* centinelTopUp() {
  yield takeEvery('CENTINEL_TOP_UP', function* ({ payload }) {
    if (payload) {
      try {
        yield put({
          type: actions.CENTINEL_TOPPING_UP,
        });
        const state = yield select();
        const walletData = state.Financials.walletId;

        const apiResult = yield centinelTopUpRequest(payload);
        const centinelTopUpResult = apiResult.data;
        if (centinelTopUpResult.success) {
          const apiResultB = yield fetchBalanceNew(walletData.walletId);
          const fetchBalanceResult = apiResultB.data;
          if (fetchBalanceResult.success) {
            yield put({
              type: actions.CENTINEL_TOP_UP_SUCCESS,
              walletDetails: fetchBalanceResult.data,
              result: centinelTopUpResult.data,
            });
          } else {
            yield put({
              type: actions.CENTINEL_TOP_UP_SUCCESS,
              walletDetails: null,
              result: centinelTopUpResult.data,
            });
          }
        } else {
          yield put({
            type: actions.CENTINEL_TOP_UP_FAILED,
            message: centinelTopUpResult.message,
          });
        }
      } catch (error) {
        yield put({
          type: actions.CENTINEL_TOP_UP_FAILED,
          message: 'Topup uncessful, cannot process transaction.',
        });
      }
    }
  });
}
//
export function* searchWithdrawTransaction() {
  yield takeEvery('SEARCH_WITHDRAW_TRANSACTION', function* (payload) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    payload.payload.mid = merchantId;
    const loadmore = payload.payload.loadmore;
    try {
      if (loadmore) {
        yield put({
          type: actions.SEARCH_LOADMORE_WITHDRAW_TRANSACTION,
        });
      } else {
        yield put({
          type: actions.SEARCHING_WITHDRAW_TRANSACTION,
        });
      }
      const apiResult = yield searchWithdrawResult(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        const currentList = payload.payload.currentUserList;
        const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
        yield put({
          type: actions.SEARCHED_WITHDRAW_TRANSACTION,
          payload: finalData,
          pagination: result.data.pagination,
        });
      } else {
        yield put({
          type: actions.SEARCH_WITHDRAW_TRANSACTION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SEARCH_WITHDRAW_TRANSACTION_ERROR,
        payload: error,
      });
    }
  });
}
//
export function* searchSplitTransaction() {
  yield takeEvery('SEARCH_SPLIT_TRANSACTION', function* (payload) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    payload.payload.mid = merchantId;
    const loadmore = payload.payload.loadmore;
    try {
      if (loadmore) {
        yield put({
          type: actions.SEARCH_LOADMORE_SPLIT_TRANSACTION,
        });
      } else {
        yield put({
          type: actions.SEARCHING_SPLIT_TRANSACTION,
        });
      }
      const apiResult = yield searchSplitResult(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        const currentList = payload.payload.currentUserList;
        const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
        yield put({
          type: actions.SEARCHED_SPLIT_TRANSACTION,
          payload: finalData,
          pagination: result.data.pagination,
        });
      } else {
        yield put({
          type: actions.SEARCH_SPLIT_TRANSACTION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SEARCH_SPLIT_TRANSACTION_ERROR,
        payload: error,
      });
    }
  });
}
//
export function* searchTopupTransaction() {
  yield takeEvery('SEARCH_TOPUP_TRANSACTION', function* (payload) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    payload.payload.mid = merchantId;
    const loadmore = payload.payload.loadmore;
    try {
      if (loadmore) {
        yield put({
          type: actions.SEARCH_LOADMORE_TOPUP_TRANSACTION,
        });
      } else {
        yield put({
          type: actions.SEARCHING_TOPUP_TRANSACTION,
        });
      }
      const apiResult = yield searchTopupResult(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        const currentList = payload.payload.currentUserList;
        const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
        yield put({
          type: actions.SEARCHED_TOPUP_TRANSACTION,
          payload: finalData,
          pagination: result.data.pagination,
        });
      } else {
        yield put({
          type: actions.SEARCH_TOPUP_TRANSACTION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SEARCH_TOPUP_TRANSACTION_ERROR,
        payload: error,
      });
    }
  });
}
//
export function* searchOnlinePaymentTransaction() {
  yield takeEvery('SEARCH_ONLINE_PAYMENT_TRANSACTION', function* (payload) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    payload.payload.mid = merchantId;
    const loadmore = payload.payload.loadmore;
    try {
      if (loadmore) {
        yield put({
          type: actions.SEARCH_LOADMORE_ONLINE_PAYMENT_TRANSACTION,
        });
      } else {
        yield put({
          type: actions.SEARCHING_ONLINE_PAYMENT_TRANSACTION,
        });
      }
      const apiResult = yield searchOnlinePaymentResult(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        const currentList = payload.payload.currentUserList;
        const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
        yield put({
          type: actions.SEARCHED_ONLINE_PAYMENT_TRANSACTION,
          payload: finalData,
          pagination: result.data.pagination,
        });
      } else {
        yield put({
          type: actions.SEARCH_ONLINE_PAYMENT_TRANSACTION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SEARCH_ONLINE_PAYMENT_TRANSACTION_ERROR,
        payload: error,
      });
    }
  });
}
//

// note
export function* searchAutoSettlement() {
  yield takeEvery('SEARCH_AUTO_SETTLEMENT', function* ({ payload }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    payload.mid = merchantId;
    const payData = {
      loadMore: payload.loadmore,
      mid: merchantId,
      page: payload.page,
      walletId: payload.walletId,
      type: "auto-settlement",
      search: payload.search,
    }
    const loadmore = payload.loadmore;
    try {
      if (loadmore) {
        yield put({
          type: actions.LOADMORE_AUTOSETTLEMENT,
        });
      } else {
        yield put({
          type: actions.GETTING_ALL_AUTOSETTLEMENT,
        });
      }
    const apiResultD = yield getAutoSettlement(payData);
    const result = apiResultD.data || null;
      if (result.success) {
        const currentList = payload.currentUserList;
        const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
        yield put({
          type: actions.SEARCHED_AUTO_SETTLEMENT,
          payload: finalData,
          pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.SEARCH_AUTO_SETTLEMENT_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SEARCH_AUTO_SETTLEMENT_ERROR,
      });
    }
  });
}
//
export function* fetchEnrolledBankAccount() {
  yield takeEvery('FETCH_ENROLLED_BANK_ACCOUNT', function* (payload) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    payload.payload.mid = merchantId;
    const loadingBalances = state.Financials.loadingBalances;
    const loadmore = payload.payload.loadmore;
    if (!loadingBalances) {
      try {
        if (loadmore) {
          yield put({
            type: actions.FETCH_LOADMORE_ENROLLED_BANK_ACCOUNT,
          });
        } else {
          yield put({
            type: actions.FETCHING_ENROLLED_BANK_ACCOUNT,
          });
        }
        const apiResult = yield fetchEnrolledBankAccountResult(payload.payload);
        const result = apiResult.data;
        if (result.success) {
          const currentList = payload.payload.currentBankList;
          const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
          yield put({
            type: actions.FETCHED_ENROLLED_BANK_ACCOUNT,
            payload: finalData,
            pagination: result.data.pagination,
          });
        } else {
          yield put({
            type: actions.FETCH_ENROLLED_BANK_ACCOUNT_FAILED,
          });
        }
      } catch (error) {
        yield put({
          type: actions.FETCH_ENROLLED_BANK_ACCOUNT_ERROR,
          payload: error,
        });
      }
    }
  });
}
export function* fetchAllWallets() {
  yield takeEvery('FETCH_ALL_WALLETS', function* ({payload}) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : payload;
      try {
          yield put({
            type: actions.FETCHING_ALL_WALLETS,
          });
        const apiResult = yield fetchingAllWallets(merchantId);
        const result = apiResult.data;

        // FOR FILTERING ALL WALLETS IF SUBWALLET USER (SUBWALLET USER CAN ONLY SEE THEIR WALLET)
        if (role === 'Subwallet User' && result.success) {
          let walletLinkData = state.Financials.walletLinkData;
          if (!walletLinkData) {
            const username = state.Auth.identity.username;
            const apiResultWalletLinkData = yield fetchWalletLinkRequest({username});
            const resultWalletLinkData = apiResultWalletLinkData.data;
            if (resultWalletLinkData.success) {
              walletLinkData = resultWalletLinkData
            }
          }
          if (walletLinkData) {
            const filtereAlldWalletArr = result.data.filter(wallet => wallet.walletId === walletLinkData.data.walletId)
            result.data = [...filtereAlldWalletArr]
          }
        }
        
        if (result.success) {
          yield put({
            type: actions.FETCHED_ALL_WALLETS,
            payload: result.data,
          });
        } else {
          yield put({
            type: actions.FETCH_ALL_WALLETS_FAILED,
            payload: 'Error in fetching All Wallets'
          });
        }
      } catch (error) {
        yield put({
          type: actions.FETCH_ALL_WALLETS_FAILED,
          payload: 'Error in fetching All Wallets'
        });
      }
  });
}
export function* saveWallet() {
  yield takeEvery('SAVE_WALLET', function* ({payload}) {
      try {
        yield put({
          type: actions.SAVING_WALLET,
        });
        const state = yield select();
        const allWallet = state.Financials.allWallets;
        const apiResult = yield savingWallet(payload);
        const result = apiResult.data;
        if (result.success) {
          const walletData = {
            walletId: result.data.walletId,
            balance: 'For Approval',
            walletName: result.data.walletName,
            accountNo: result.data.accountNo,
            status: result.data.status,
          };
          const finalData = allWallet ? allWallet.concat([walletData]) : [walletData];
          yield put({
            type: actions.SAVED_WALLET,
            payload: result.data,
          });
          yield put({
            type: actions.FETCHED_ALL_WALLETS,
            payload: finalData,
          });
        } else {
          yield put({
            type: actions.SAVE_WALLET_FAILED,
            payload: 'Error in saving wallet!'
          });
        }
      } catch (error) {
        yield put({
          type: actions.FETCH_ALL_WALLETS_FAILED,
          payload: 'Error in saving wallet!'
        });
      }
  });
}

export function* fetchWalletByMid() {
  yield takeEvery('FETCH_WALLET_BY_MID', function* () {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
      try {
          yield put({
            type: actions.FETCHING_WALLET_BY_MID,
          });
        const apiResult = yield fetchWalletByMerchantId(merchantId);
        const result = apiResult.data;
        if (result.success) {
          yield put({
            type: actions.FETCHED_WALLET_BY_MID,
            payload: result.data,
          });
        } else {
          yield put({
            type: actions.FETCH_WALLET_BY_MID_FAILED,
            payload: 'Error in fetching All Wallets'
          });
        }
      } catch (error) {
        yield put({
          type: actions.FETCH_WALLET_BY_MID_FAILED,
          payload: 'Error in fetching All Wallets'
        });
      }
  });
}

export function* approveWallet() {
  yield takeEvery('APPROVE_WALLET', function* ({payload}) {
      try {
        yield put({
          type: actions.APPROVING_WALLET,
        });
        const apiResult = yield approvingWallet(payload);
        const result = apiResult.data;        
        if (result.success) {
          yield put({
            type: actions.APPROVED_WALLET,
            payload: result.data,
          });
        } else {
          yield put({
            type: actions.APPROVE_WALLET_FAILED,
            message: result.message
          });
        }
      } catch (error) {
        yield put({
          type: actions.APPROVE_WALLET_FAILED,
          message: 'Error in saving wallet!'
        });
      }
  });
}


// WALLET LINK
export function* fetchWalletLink() {
  yield takeEvery('FETCH_WALLET_LINK', function* ({payload}) {
    try {
      yield put({
        type: actions.FETCH_WALLET_LINK_LOADING,
      });
      
      const apiResult = yield fetchWalletLinkRequest(payload);
      const result = apiResult.data;
      if (apiResult.status === 200) {
        yield put({
          type: actions.FETCH_WALLET_LINK_SUCCESS,
          payload: result,
        });
      } else {
        yield put({
          type: actions.FETCH_WALLET_LINK_FAILED,
          payload: {
            data: apiResult,
            message: 'Fetch Wallet Link Failed'
          },
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_WALLET_LINK_FAILED,
        payload: {
          data: error,
          message: 'Fetch Wallet Link Error'
        },
      });
    }
  });
}

// CANCEL ONLINE PAYMENT
export function* cancelOnlinePayment() {
  yield takeEvery('CANCEL_ONLINE_PAYMENT', function* ({payload}) {
    try {
      yield put({
        type: actions.CANCEL_ONLINE_PAYMENT_LOADING,
      });
      
      const onnlinePayment = yield fetchOnlinePaymentRequest(payload.requestId)
      if (onnlinePayment.data.success) {
        const newPayload = {
          transactionId: onnlinePayment.data.data.Id,
          body: payload.body,
        }
        const apiResult = yield cancelOnlinePaymentRequest(newPayload);
        const result = apiResult.data;
        if (result.success) {
          yield put({
            type: actions.CANCEL_ONLINE_PAYMENT_SUCCESS,
            payload: result,
          });
        } else {
          yield put({
            type: actions.CANCEL_ONLINE_PAYMENT_FAILED,
            payload: {
              data: apiResult,
              message: 'Cancel Online Payment Failed'
            },
          });
        }
      } else {
        yield put({
          type: actions.CANCEL_ONLINE_PAYMENT_FAILED,
          payload: {
            data: onnlinePayment,
            message: 'Cancel Online Payment Failed'
          },
        });
      }
      
    } catch (error) {
      yield put({
        type: actions.CANCEL_ONLINE_PAYMENT_FAILED,
        payload: {
          data: error,
          message: 'Cancel Online Payment Error'
        },
      });
    }
  });
}

export function* sendOtpCentinelTopup() {
  yield takeEvery('SEND_OTP_CENTINEL_TOPUP', function* ({ payload }) {
      try {
        yield put({
          type: actions.SEND_OTP_CENTINEL_TOPUP_LOADING,
        });
        const apiResult = yield requestOtpCentinelTopup(payload);
        const result = apiResult.data.data || null;
        if (result.success) {
          yield put({
            type: actions.SEND_OTP_CENTINEL_TOPUP_SUCCESS,
            payload: result
          });
        } else {
          yield put({
            type: actions.SEND_OTP_CENTINEL_TOPUP_ERROR,
            payload: result.message
          });
        }
      } catch (error) {
        yield put({
          type: actions.SEND_OTP_CENTINEL_TOPUP_ERROR,
          payload: "Failed to send OTP!"
        });
      }
  });
}

export function* resendOtpCentinelTopup() {
  yield takeEvery('RESEND_OTP_CENTINEL_TOPUP', function* ({ payload }) {
      try {
        yield put({
          type: actions.RESEND_OTP_CENTINEL_TOPUP_LOADING,
        });
        const apiResult = yield requestResendOtpCentinelTopup(payload);
        const result = apiResult.data.data || null;
        if (result.success) {
          yield put({
            type: actions.RESEND_OTP_CENTINEL_TOPUP_SUCCESS,
            payload: result.message
          });
        } else {
          yield put({
            type: actions.RESEND_OTP_CENTINEL_TOPUP_ERROR,
            payload: result.message
          });
        }
      } catch (error) {
        yield put({
          type: actions.RESEND_OTP_CENTINEL_TOPUP_ERROR,
          payload: "Failed to resend OTP!"
        });
      }
  });
}

export function* verifyOtpCentinelTopup() {
  yield takeEvery('VERIFY_OTP_CENTINEL_TOPUP', function* ({ payload }) {
      try {
        yield put({
          type: actions.VERIFY_OTP_CENTINEL_TOPUP_LOADING,
        });
        const apiResult = yield requestVerifyOtpCentinelTopup(payload);
        const result = apiResult.data.data || null;
        if (result.success) {
          yield put({
            type: actions.VERIFY_OTP_CENTINEL_TOPUP_SUCCESS,
            payload: result.message
          });
        } else {
          yield put({
            type: actions.VERIFY_OTP_CENTINEL_TOPUP_ERROR,
            payload: result.message
          });
        }
      } catch (error) {
        yield put({
          type: actions.VERIFY_OTP_CENTINEL_TOPUP_ERROR,
          payload: "Failed to resend OTP!"
        });
      }
  });
}

export function* fetchMerchantBirDocuments() {
  yield takeEvery('FETCH_MERCHANT_BIR_DOCUMENTS', function* ({ payload }) {
      try {
        yield put({
          type: actions.FETCH_MERCHANT_BIR_DOCUMENTS_LOADING,
        });
        const apiResult = yield fetchMerchantBirDocumentsRequest(payload);
        const result = apiResult.data;
        if (result.success) {
          yield put({
            type: actions.FETCH_MERCHANT_BIR_DOCUMENTS_SUCCESS,
            payload: result.data
          });
        } else {
          yield put({
            type: actions.FETCH_MERCHANT_BIR_DOCUMENTS_FAILED,
            payload: apiResult
          });
        }
      } catch (error) {
        yield put({
          type: actions.FETCH_MERCHANT_BIR_DOCUMENTS_FAILED,
          payload: error
        });
      }
  });
}

export function* submitMerchantBirDocuments() {
  yield takeEvery('SUBMIT_MERCHANT_BIR_DOCUMENTS', function* ({ payload }) {
      try {
        yield put({
          type: actions.SUBMIT_MERCHANT_BIR_DOCUMENTS_LOADING,
        });
        const apiResult = yield submitMerchantBirDocumentsRequest(payload);
        const result = apiResult.data;
        if (result.success) {
          yield put({
            type: actions.SUBMIT_MERCHANT_BIR_DOCUMENTS_SUCCESS,
            payload: result.data
          });
        } else {
          yield put({
            type: actions.SUBMIT_MERCHANT_BIR_DOCUMENTS_FAILED,
            payload: apiResult
          });
        }
      } catch (error) {
        yield put({
          type: actions.SUBMIT_MERCHANT_BIR_DOCUMENTS_FAILED,
          payload: error
        });
      }
  });
}

export function* updateBirDocumentsStatus() {
  yield takeEvery('UPDATE_BIR_DOCUMENTS_STATUS', function* ({ payload }) {
    try {
      yield put({
        type: actions.UPDATE_BIR_DOCUMENTS_STATUS_LOADING,
      });
      const apiResult = yield updateBirDocumentsStatusRequest(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.UPDATE_BIR_DOCUMENTS_STATUS_SUCCESS,
          payload: result.data
        });
      } else {
        yield put({
          type: actions.UPDATE_BIR_DOCUMENTS_STATUS_FAILED,
          payload: apiResult
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_BIR_DOCUMENTS_STATUS_FAILED,
        payload: error
      });
    }
  });
}

export function* deleteMerchantBirDocuments() {
  yield takeEvery('DELETE_MERCHANT_BIR_DOCUMENTS', function* ({ payload }) {
    try {
      yield put({
        type: actions.DELETE_MERCHANT_BIR_DOCUMENTS_LOADING,
      });
      const apiResult = yield deleteMerchantBirDocumentsRequest(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.DELETE_MERCHANT_BIR_DOCUMENTS_SUCCESS,
          payload: result.data
        });
      } else {
        yield put({
          type: actions.DELETE_MERCHANT_BIR_DOCUMENTS_FAILED,
          payload: apiResult
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_MERCHANT_BIR_DOCUMENTS_FAILED,
        payload: error
      });
    }
  });
}

export function* fetchFileBase64() {
  yield takeEvery('FETCH_FILE_BASE64', function* ({ payload }) {
    try {
      yield put({
        type: actions.FETCH_FILE_BASE64_LOADING,
      });
      const apiResult = yield fetchFileBase64Request(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCH_FILE_BASE64_SUCCESS,
          payload: result.data
        });
      } else {
        yield put({
          type: actions.FETCH_FILE_BASE64_FAILED,
          payload: apiResult
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_FILE_BASE64_FAILED,
        payload: error
      });
    }
  });
}
//Enrolled Bank Account Sub-Module
export function* requestRemoveEnrolledBankOtp() {
  yield takeEvery('REQUEST_REMOVE_ENROLLED_BANK_OTP', function* (payload) {
    try {
      yield put({
        type: actions.REQUESTING_REMOVE_ENROLLED_BANK_OTP,
        loading: true,
        otpRemoveBankLimitReachMessage: false,
        removeEnrolleBankError: null,
      });
      const apiResult = yield requestingRemoveEnrolledBankOtp(payload.payload);
      const requestData = apiResult.data;
      if (requestData.success) {
        const result = requestData.data;
        if (result.success) {
          const message = `OTP successfully sent to ${result.data.email}`;
          yield put({
            type: actions.SET_OTP_REMOVE_MODAL,
            otpData: result,
            otpRemoveBankBanner: true,
            otpRemoveBankModal: true,
            otpMessage: message,
            otp_email: result.data.email,
            isConfirmRemovalModal: false,
          });
          yield put({
            type: actions.REQUESTING_REMOVE_ENROLLED_BANK_OTP,
            loading: false,
          });
          yield put({
            type: actions.OTP_REMOVE_BANK_TOKEN,
            payload: result.otp_token,
          });
        } else {
          yield put({
            type: actions.REMOVE_ENROLLED_BANK_ERROR,
            error: 'Something went wrong while requesting OTP, please TRY AGAIN!',
          });
          yield put({
            type: actions.REQUESTING_REMOVE_ENROLLED_BANK_OTP,
            loading: false,
          });
        }
      } else {
        yield put({
          type: actions.REMOVE_ENROLLED_BANK_ERROR,
          error: 'Something went WRONG, please TRY AGAIN!',
        });
        yield put({
          type: actions.REQUESTING_REMOVE_ENROLLED_BANK_OTP,
          loading: false,
        });
      }
    } catch (error) {
      yield put({
        type: actions.REMOVE_ENROLLED_BANK_ERROR,
        error: 'Something went WRONG, please TRY AGAIN!',
      });
    }
  });
}
export function* verifyRemoveEnrolledBankOtp() {
  yield takeEvery('VERIFY_REMOVE_ENROLLED_BANK_OTP', function* ({payload}) {
    try {
      yield put({
        type: actions.VERIFYING_REMOVE_BANK_OTP,
        loading: true,
        verifyingEnrolleBankError: null,
      });
      yield put({
        type: actions.CLEAR_OTP_REMOVE_BANK_MESSAGE,
      });
      const state = yield select();
      const otpRemoveBankData = state.Financials.otpRemoveBankData;
      const apiResult = yield verifyingRemoveBankOtp(payload);
      const validateData = apiResult.data;
      if (validateData){
        const result = validateData.data;
        if (result.success) {
          yield put({
            type: actions.VALIDATED_REMOVE_ENROLLED_BANK_OTP,
            payload: result,
            verifiedOtp: result.success,
            isErrorVerifyRemoveBankOtp: false,
            verifyRemoveBankOtpMessage: result.message,
            otpRemoveBankModal: false,
            otpRemoveBankBanner: false,
          });
          yield put({
            type: actions.CLEAR_REMOVE_BANK_OTP_DATA,
          });
        } else {
          let otpModal = true;
          let limitReachMessage = null;
          if (payload.otpAttempts === otpRemoveBankData.otp_attempts) {
            limitReachMessage = true;
            // result.message = null;
          }
          yield put({
            type: actions.VALIDATED_REMOVE_ENROLLED_BANK_OTP,
            payload: result,
            verifyRemoveBankOtpMessage: result.message,
            isErrorVerifyRemoveBankOtp: true,
            otpRemoveBankModal: otpModal,
            otpRemoveBankBanner: otpModal,
            limitReachMessage,
          });
        }
      } else {
        yield put({
          type: actions.VERIFYING_ENROLLED_BANK_ERROR,
          error: 'Something went wrong while verifying OTP, please TRY AGAIN!',
        });
        yield put({
          type: actions.VERIFYING_REMOVE_BANK_OTP,
          loading: false,
        });
      }
    } catch (error) {
      yield put({
        type: actions.VERIFYING_ENROLLED_BANK_ERROR,
        error: 'Something went WRONG, please TRY AGAIN!',
      });
      yield put({
        type: actions.CLEAR_REMOVE_BANK_OTP_DATA,
      });
    }
  });
}
//END

function verifyingRemoveBankOtp(data) {
  return post(`onetimepasswords/validate_remove_enrolled_bank_otp`, data);
}
function requestingRemoveEnrolledBankOtp(data) {
  return post(`onetimepasswords/request_remove_enrolled_bank_otp`, data);
}
function savingWallet(data) {
  return post(`walletmanagements/add_wallet`, data);
}
function fetchingAllWallets(mid) {
  return get(`walletmanagements/fetch_wallet_mid/${mid}`);
}
function fetchingInitialWallet(mid) {
  return get(`merchantwallets/fetch_initial_wallet_id/${mid}`);
}
function resendingOtp(data) {
  return post(`withdrawsmsreqs/resend/otp-v2/`, data);
}
// function withdrawalRequesting() {
//   return get(`disbursementreqs/v-2/`);
// }
// function withdrawalRequestingNew(mid, role, selectedMerchant) {
//   if (role === 'Administrator' && selectedMerchant === 'All Merchant') {
//     // return get(`disbursementreqs/v-2/${selectedMerchant}`);
//     return get(`topuptransactions/get_wallet_transaction/${selectedMerchant}`);
//   } else if (role === 'Administrator') {
//     return get(`topuptransactions/get_wallet_transaction/${mid}`);
//   } else if (role === 'Merchant Admin') {
//     return get(`topuptransactions/get_wallet_transaction/${mid}`);
//   }
// }
function getAutoSettlement(data) {
  const newData = {
    mid: data.mid,
    page: data.page,
    walletId: data.walletId,
    searchValue: data.search,
  }
  return get(`creditautosettlements/get/auto_settlement/`, newData, data.page, null, null);
}
function withdrawalTransactionRequest(data) {
  const newData = {
    mid: data.mid,
    page: data.page,
    walletId: data.walletId
  }
  return get(`topuptransactions/get/withdraw_transaction/`, newData, data.page, null, null);
}
function splitTransactionRequest(data) {
  const newData = {
    mid: data.mid,
    page: data.page,
    walletId: data.walletId,
    // tenantId: data.tenantId,
  }
  return get(`topuptransactions/get/split_payment_transaction/`, newData, data.page, null, null);
}
function topupTransactionRequest(data) {
  const newData = {
    mid: data.mid,
    page: data.page,
    walletId: data.walletId,
    // tenantId: data.tenantId,
  }
  return get(`topuptransactions/get/topup_transaction/`, newData, data.page, null, null);
}
function onlinePaymentTransactionRequest(data) {
  const newData = {
    mid: data.mid,
    page: data.page,
    walletId: data.walletId,
    // tenantId: data.tenantId,
  }
  return get(`integration/v1/get/online_payments_transaction/`, newData, data.page, null, null);
}
function withdrawalRequestingByReqId(id) {
  return get(`disbursementreqs/v-2/get_request_id/${id}`);
}
function verifyingOtp(signature, data) {
  return post(`disbursementreqs/withdraw/${signature}`, data);
}
function verifyingOtpCorporate(data) {
  return post(`disbursementreqs/withdraw_corporate`, data);
}
// function fetchBalance(mid) {
//   return get(`merchantwallets/get_by_merchant_id/${mid}`);
// }
function fetchBalanceNew(walletId) {
  return get(`merchantwallets/get_by_wallet_id/${walletId}`);
}
function requestingOtp(data) {
  return post(`withdrawsmsreqs/request_otp`, data);
}
// function fetchWithdrawableBalance(mid) {
//   return get(`integration/v1/get_balance/${mid}`);
// }
// function fetchWithdrawableBalanceNew(walletId) {
//   return get(`integration/v1/get_balance_wallet_id/${walletId}`);
// }
function fetchBankAccount(mid) {
  return get(`merchantwithdrawalaccounts/fetch_by_mid/${mid}`);
}
function getLimit(mid) {
  return get(`payment_transactions/get_aggregated_report/${mid}`);
}
function getSupportedBanks() {
  return get(`supportedbanks/get_all_supported_banks`);
}
function sendOtpSms(payload) {
  return post(`withdrawsmsreqs/`, payload);
}
function insertBankAccount(payload) {
  return post(`merchantwithdrawalaccounts/`, payload);
}
function editBankAccount(payload) {
  return PUT(`merchantwithdrawalaccounts/update_bank_account/${payload.Id}`, payload);
}
function deleteBankAccount(id) {
  return PUT(`merchantwithdrawalaccounts/delete/${id}`);
}
function changeFavoriteValue(payload) {
  return PUT(`merchantwithdrawalaccounts/change_favorite/${payload.id}`, payload);
}
function changeStatusValue(payload) {
  return PUT(`merchantwithdrawalaccounts/change_status/${payload.id}`, payload);
}
function resendOtpSms(payload) {
  return post(`withdrawsmsreqs/resend/otp/`, payload);
}
function downloadWithdrawalReport(payload) {
  return post(`downloadtransactions/download/withdrawal_request_ui/`, payload);
}
function downloadSplitReport(payload) {
  return post(`downloadtransactions/download/split_payment_request_ui/`, payload);
}
function downloadTopupReport(payload) {
  return post(`downloadtransactions/download/topup_request_ui/`, payload);
}
function downloadAutoSettlementReport(payload) {
  return post(`downloadtransactions/download/auto_settlement_ui/`, payload);
}
function downloadOnlinePaymentReport(payload) {
  return post(`downloadtransactions/download/online_payment_ui/`, payload);
}
function updatingWithdrawalStatus(payload) {
  return post(`disbursementreqs/update_withdrawal_status/`, payload);
}
function insertAutoSweep(payload) {
  return post(`autosweeps/`, payload);
}
function changeAutoSweepStatus(payload) {
  return post(`autosweeps/change_status`, payload);
}
function walletTopUpRequest(payload) {
  return post(`topuptransactions/`, payload);
}
function centinelTopUpRequest(payload) {
  return post(`topuptransactions/centinel_topup`, payload);
}
function downloadBizReport(payload) {
  return post(`downloadtransactions/download_biz_wallet_report`, payload);
}
function sendingReportEmail(payload) {
  return post(`formreferences/generate_report_boc`, payload);
}
function sendingReportEmailImporterExporter(payload) {
  return post(`formreferences/generate_report_importer_exporter`, payload);
}
function searchWithdrawResult(payload) {
  return get(`topuptransactions/search/withdraw_transaction/`, payload, payload.page, null, null);
}
function searchSplitResult(payload) {
  return get(`topuptransactions/search/split_payment_transaction/`, payload, payload.page, null, null);
}
function searchTopupResult(payload) {
  return get(`topuptransactions/search/topup_transaction`, payload, payload.page, null, null);
}
function searchOnlinePaymentResult(payload) {
  return get(`integration/v1/search/online_payments_transaction`, payload, payload.page, null, null);
}
function fetchEnrolledBankAccountResult(payload) {
  return get(`merchantwithdrawalaccounts/fetch/enrolled_bank_accounts/`, payload, payload.page, null, null);
}
function fetchWalletByMerchantId(mid) {
  return get(`walletmanagements/fetch_wallet_mid/${mid}`);
}
function approvingWallet(data) {
  return post(`walletmanagements/approve_wallet`, data);
}
function fetchWalletLinkRequest(payload) {
  return post(`paybizchannelwalletlinks/fetch_wallet_link_by_username`, payload);
}
function fetchOnlinePaymentRequest(requestId) {
  return get(`integration/v1/fetch_by_request_id/${requestId}`);
}
function cancelOnlinePaymentRequest(payload) {
  return post(`integration/v1/cancel/${payload.transactionId}`, payload.body);
}
function requestOtpCentinelTopup(payload) {
  return post(`onetimepasswords/centinel_manual_topup/request_otp/`, payload);
}
function requestResendOtpCentinelTopup(payload) {
  return PUT(`onetimepasswords/centinel_manual_topup/resend_otp/`, payload);
}
function requestVerifyOtpCentinelTopup(payload) {
  return post(`onetimepasswords/centinel_manual_topup/verify_otp/`, payload);
}
function fetchMerchantBirDocumentsRequest(mid) {
  return get(`birdocuments/fetch_merchant_docs/${mid}`);
}
function submitMerchantBirDocumentsRequest(payload) {
  return post(`birdocuments/upload_docs/`, payload);
}
function updateBirDocumentsStatusRequest(payload) {
  return post(`birdocuments/update_status/`, payload);
}
function deleteMerchantBirDocumentsRequest(payload) {
  return post(`birdocuments/delete_document/`, payload);
}
function fetchFileBase64Request(awsKey) {
  return get(`birdocuments/fetch_file_base64/${awsKey}`);
}

export default function* rootSaga() {
  yield all([
    fork(loadBalance),
    fork(loadBankAccounts),
    fork(requestOtp),
    fork(verifyOtp),
    fork(loadSupportedBanks),
    fork(sendSmsOtp),
    fork(saveBankAccount),
    fork(resendOtp),
    fork(wResendOtp),
    fork(updateBankAccount),
    fork(removeBankAccount),
    fork(changeFavorite),
    fork(withdrawalTransaction),
    fork(splitTransaction),
    fork(topupTransaction),
    fork(getOnlinePaymentTransaction),
    fork(downloadWithdrawal),
    fork(downloadSplitPayment),
    fork(downloadTopup),
    fork(downloadAutoSettlement),
    fork(downloadOnlinePayment),
    fork(fetchwithdrawalRequest),
    fork(updateWithdrawalStatus),
    fork(changeStatus),
    fork(sendAutosweepOtp),
    fork(saveAutoSweep),
    fork(walletTopUp),
    fork(downloadBizwalletReport),
    fork(centinelTopUp),
    fork(sendReportEmail),
    fork(searchWithdrawTransaction),
    fork(searchSplitTransaction),
    fork(searchTopupTransaction),
    fork(searchOnlinePaymentTransaction),
    fork(fetchEnrolledBankAccount),
    fork(fetchAllWallets),
    fork(saveWallet),
    fork(fetchWalletByMid),
    fork(approveWallet),
    fork(autoSettlement),
    fork(searchAutoSettlement),
    fork(fetchWalletLink),
    fork(cancelOnlinePayment),
    fork(sendOtpCentinelTopup),
    fork(resendOtpCentinelTopup),
    fork(verifyOtpCentinelTopup),
    fork(fetchMerchantBirDocuments),
    fork(submitMerchantBirDocuments),
    fork(updateBirDocumentsStatus),
    fork(deleteMerchantBirDocuments),
    fork(fetchFileBase64),
    fork(verifyRemoveEnrolledBankOtp),
    fork(requestRemoveEnrolledBankOtp),
  ]);
}