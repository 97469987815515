import actions from './actions';

const initState = {
  authManagementRole: null,
  loadingAuthManagementRole: false,
  authManagementRoleSaved: null,
  savingAuthManagementRole: false,
  authManagementRoleFailed: null,
  rolePermission: null,
  payloadCore: null,
  selectedId: null,

  openRolePermissionDialog: false,
  statusData: null,
  addAuthRoleSuccess: false,

  fetchCoreModulesSuccess: null,
  fetchCoreModulesLoading: false,
  fetchCoreModulesFailed: null,
};

export default (state = initState, action) => {
  switch (action.type) {

    case actions.ADDING_AUTH_ROLE:
      return {
        ...state,
        authManagementRole: null,
        loadingAuthManagementRole: true,
      };
    case actions.AUTH_MANAGEMENT_ROLE_FETCHED:
      return {
        ...state,
        authManagementRole: action.payload,
        loadingAuthManagementRole: false,
      };
    case actions.AUTH_MANAGEMENT_SAVED:
      return {
        ...state,
        authManagementRoleSaved: action.payload,
        savingAuthManagementRole: false,
        authManagementRoleFailed: null,
      };
    case actions.SAVING_AUTH_MANAGEMENT:
      return {
        ...state,
        savingAuthManagementRole: true
      }
    case actions.SAVE_AUTH_MANAGEMENT_FAILED:
      return {
        ...state,
        authManagementRoleSaved: null,
        savingAuthManagementRole: false,
        authManagementRoleFailed: action.payload,
      }
    case actions.RESET_SAVE_AUTH_MANAGEMENT:
      return {
        ...state,
        authManagementRoleSaved: null,
        savingAuthManagementRole: false,
        authManagementRoleFailed: null,
      };

    case actions.OPEN_ROLE_PERMISSION_DIALOG:
      return {
        ...state,
        openRolePermissionDialog: !state.openRolePermissionDialog,
      };

    case actions.ROLE_PERMISSION_FETCHED:
      return {
        ...state,
        rolePermission: action.payload,
        payloadCore: action.payloadCore,
        selectedId: action.selectedId,
      };

    case actions.RESET_ROLE_PERMISSION_DATA:
      return {
        ...state,
        rolePermission: null,
        selectedId: null,
      };

    case actions.STATUS_FETCHED:
      return {
        ...state,
        statusData: action.payload,
      };
    case actions.AUTH_ROLE_ADDED:
      return {
        ...state,
        addAuthRoleSuccess: true,
      };
    case actions.RESET_ADD_AUTH_ROLE:
      return {
        ...state,
        addAuthRoleSuccess: false,
      };
    case actions.FETCH_CORE_MODULES_SUCCES:
      return {
        ...state,
        fetchCoreModulesSuccess: action.payload,
        fetchCoreModulesLoading: false,
        fetchCoreModulesFailed: null,
      }
    case actions.FETCH_CORE_MODULES_LOADING:
      return {
        ...state,
        fetchCoreModulesLoading: true,
      }
    case actions.FETCH_CORE_MODULES_FAILED:
      return {
        ...state,
        fetchCoreModulesSuccess: null,
        fetchCoreModulesLoading: false,
        fetchCoreModulesFailed: action.payload,
      }
    case actions.FETCH_CORE_MODULES_RESET:
      return {
        ...state,
        fetchCoreModulesSuccess: null,
        fetchCoreModulesLoading: false,
        fetchCoreModulesFailed: null,
      }
    default:
      return state;
  }
}
